import axios from "axios";
import { getData } from "../config";

export const uploadFile = async (formData, token, handleError) => {
  try {
    console.log(`Function: uploadFile called`);
    const before = new Date().valueOf();
    let url = getData("REACT_APP_STORAGE_URL") + "/api/file-metas"
    let response = await axios.post(url, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    });
    const after = new Date().valueOf();
    console.log(`Function: uploadFile, Time Elapsed: ${(after - before) / 1000} sec`);
    return response.data;
  } catch (err) {
    handleError(err)
    console.log(`Error in uploadFile: ${err.message}`);
  }
};

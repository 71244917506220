import React from 'react';
import './Header.css';
import logo from '../../Assets/logo.png';
import UserService from '../../UserService/UserService'
import { getData } from '../../config';
import { Button } from '@mui/material';
import { Logout } from '@mui/icons-material';
const Header = () => {
  function handleLogout() {
    localStorage.clear();
    UserService.doLogout({
      redirectUri: getData("REACT_APP_FRONTEND_BASEURL"),
    });
  }
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logistiex" style={{ width: 'auto', height: '60px' }} />
      </div>
      <Button className="logout-btn" variant="contained" color="primary" sx={{ mt: 2 }} onClick={() => handleLogout()} startIcon={<Logout />}>
        Logout
      </Button>
    </header>
  );
};

export default Header;

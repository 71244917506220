import { getData } from "../config.jsx";

let _kc;

const initKeycloak = (onAuthenticatedCallback, keyCloak) => {
  console.log("initializing keycloak");
  _kc = keyCloak
  _kc
    .init({
      onLoad: "check-sso",
      checkLoginIframe: false,
      enableLogging: true,
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (authenticated) {
        console.log("initialized keycloak");
        onAuthenticatedCallback();
      } else {
        console.log("user is not authenticated..!");
        let frontendUrl = getData("REACT_APP_FRONTEND_BASEURL")
        doLogin({ redirectUri: frontendUrl });
      }
    })
    .catch(console.error);
};

const doLogin = (params) => _kc.login(params);

const doLogout =(params) => _kc.logout(params);

const getToken = () => _kc.token;


const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback = () => { }) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const parsedToken = () => _kc.tokenParsed;
const kcData = () => _kc;

const userinfo = () => _kc.loadUserProfile;

const hasRole = (roles) => {
  let kcRoles = [];
  for (let i = 0; i < _kc.realmAccess.roles.length; i++) {
    const dotIndex = _kc.realmAccess.roles[i].indexOf('.');
    kcRoles.push(_kc.realmAccess.roles[i].slice(dotIndex + 1))
  }
  return roles.some(role => kcRoles.includes(role));
};

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  parsedToken,
  kcData,
  userinfo,
  _kc,
};

export default UserService;



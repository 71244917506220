export const platformOptions = [
    { name: "Amazon", code: "az" },
    { name: "Flipkart", code: "fk" },
    { name: "Meesho", code: "ms" },
    { name: "Ajio", code: "aj" },
    { name: "Myntra", code: "mn" },
    { name: "Nykaa", code: "nk" },
    { name: "Snapdeal", code: "sd" },
    { name: "Jio Mart", code: "jm" },
    { name: "Limeroad", code: 'lr' }
]
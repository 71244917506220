export const validateFile = (file) => {
    let validationError = ""
    if (!file.type.match('image/jpeg')) {
        validationError='Only .jpg files are allowed';
    }
    if (file.size < 200 * 1024 || file.size > 2 * 1024 * 1024) {
      validationError='File size must be between 200KB and 2MB';
    }
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width > 1500 || img.height > 2000) {
        validationError='Image resolution exceeds the allowed dimensions (1500x2000 pixels)';
      }
    };
    return validationError
  };

import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn, YouTube } from '@mui/icons-material';

const Footer = () => {
    const handleIconClick = (url) => {
        window.open(url, '_blank');
      };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#03045E',
        color: '#fff',
        padding: '15px 20px',
        position:"fixed",
        bottom:0,
        left:0,
        right:0,
        marginTop:"20px",
        zIndex:4
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
       Copyright © 2024 by Noetic LogistieX Pvt. Ltd.
      </Typography>
      <Box>
        <IconButton
          sx={{
            color: '#fff',
            backgroundColor: '#2325b3ba',
            margin: '0 5px',
            '&:hover': {
              backgroundColor: '#2325b3ba',
            },
          }}
          onClick={() => handleIconClick('https://www.facebook.com/profile.php?id=61555412746291')}
        >
          <Facebook />
        </IconButton>
        <IconButton
          sx={{
            color:'#fff',
            backgroundColor: '#2325b3ba',
            margin: '0 5px',
            '&:hover': {
              backgroundColor: '#2325b3ba',
            },
          }}
          onClick={() => handleIconClick(' https://x.com/logistiex')}
        >
          <Twitter />
        </IconButton>
        <IconButton
          sx={{
            color: '#fff',
            backgroundColor: '#2325b3ba',
            margin: '0 5px',
            '&:hover': {
              backgroundColor: '#2325b3ba',
            },
          }}
          onClick={() => handleIconClick(' https://www.instagram.com/logistiex/')}
        >
          <Instagram />
        </IconButton>
        <IconButton
          sx={{
            color: '#fff',
            backgroundColor: '#2325b3ba',
            margin: '0 5px',
            '&:hover': {
              backgroundColor: '#2325b3ba',
            },
          }}
          onClick={() => handleIconClick('https://www.linkedin.com/company/logistiex/')}
        >
          <LinkedIn />
        </IconButton>
        <IconButton
          sx={{
            color: '#fff',
            backgroundColor: '#2325b3ba',
            margin: '0 5px',
            '&:hover': {
              backgroundColor: '#2325b3ba',
            },
          }}
          onClick={() => handleIconClick(' https://www.youtube.com/@NoeticLogistiex')}
        >
          <YouTube />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;

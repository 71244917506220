export const downloadImageAsFile = (filename, content, type) => {
    if (typeof window === "undefined") {
      return;
    }
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.setAttribute("visibility", "hidden");
    link.download = filename;
    const blobUrl = URL.createObjectURL(blob);
    link.href = blobUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 100);
  };
import React from 'react';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <style jsx>{`
        .loader-container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
        }
        
        .loader {
          border: 8px solid #0d47a1; 
          border-top: 8px solid #ffffff; 
          border-radius: 50%;
          width: 50px;
          height: 50px;
          animation: spin 1s linear infinite; 
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default Loader;

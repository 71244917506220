import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Typography,
  Grid,
  CardActions,
  CardMedia
} from '@mui/material';
import './imageTransformation.css';
import DownloadIcon from '@mui/icons-material/Download';
export const ConvertedImages = (props) => {
  const { imageUrls, getGridColumns, handleDownload, platformErrors } = props;
  const handleDownloadAll = () => {
    imageUrls.forEach((url) => {
      handleDownload(url.signedUrl);
    });
  };
  return (
    <>
      {imageUrls.length > 0 &&
        <Box mt={2} borderTop={"2px solid gray"} borderBottom={"2px solid gray"}>
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', margin: '16px 0' }}
          >
            Converted Images
          </Typography>
          <Grid container spacing={2}>
            {imageUrls.map((url, index) => (
              <Grid item xs={12} sm={6} md={getGridColumns()} key={index}>
                <Card sx={{ position: "relative", maxHeight: 200, maxWidth: 345, margin: '10px auto' }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={url.signedUrl}
                    alt={`Image ${index + 1}`}
                  />
                  <div
                    style={{
                      position: "absolute",
                      left: 4,
                      top: 4,
                      border: 0,
                      margin: 0,
                      color: "#012ca0d4",
                      background: "none !important"
                    }}
                  >
                    {url.platformName}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: 2,
                      right: 2,
                      border: 0,
                      margin: 0,
                      color: "#012ca0d4",
                      background: "none !important"
                    }}
                    onClick={() => handleDownload(url.signedUrl)}
                  >
                    <DownloadIcon />
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
          {imageUrls.length > 1 && (
            <CardActions sx={{ justifyContent: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={handleDownloadAll}
                startIcon={<DownloadIcon />}
              >
                Download All
              </Button>
            </CardActions>
          )}
        </Box>
      }
      {platformErrors.length > 0 &&
        <Card sx={{ maxWidth: 770, margin: '0px auto', mt: 2, mb: 8, boxShadow: 3, padding: 2 }}>
          {platformErrors.map((error, index) => (
            <Typography key={index} sx={{ color: 'red' }}>
              {error}
            </Typography>
          ))}
        </Card>}
    </>
  );
};



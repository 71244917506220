import React from "react";
import { render } from "react-dom";
import App from './App'
import UserService from "../src/UserService/UserService";
import { configData, getData } from "./config";
import Keycloak from "keycloak-js";

(async () => {
  await configData();
  const _kc = new Keycloak({
    "realm": getData("REACT_APP_KEYCLOAK_REALM"),
    "url": getData("REACT_APP_KEYCLOAK_AUTH_SERVER_URL"),
    "resource": getData("REACT_APP_KEYCLOAK_RESOURCE"),
    "clientId": getData("REACT_APP_KEYCLOAK_CLIENT_ID")
  });
  const renderApp = () => render(<App />, document.getElementById("root"));
  UserService.initKeycloak(renderApp, _kc);
})();
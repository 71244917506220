import axios from "axios";
let configurations = [];
const { v4: uuidv4 } = require('uuid');

export const configData = async () => {
  try {
    let res = await axios.get("https://gandaki.logistiex.com/api/configs");
    configurations = res.data.configuration;
  } catch (err) {
    console.log(err);
  }
};

export const getData = (key) => {
  const data = configurations.find((item) => item.config_key === key);
  const defaults = {
    "REACT_APP_BACKEND_BASE_URL": "https://imgtrans-api-dev-ouucyoijra-uc.a.run.app",
    "REACT_APP_STORAGE_URL": "https://storage.dev.logistiex.com",
    "REACT_APP_KEYCLOAK_REALM": "janus-test",
    "REACT_APP_KEYCLOAK_AUTH_SERVER_URL": "https://uacc.logistiex.com",
    "REACT_APP_KEYCLOAK_RESOURCE": "image-transformation-ui",
    "REACT_APP_KEYCLOAK_CLIENT_ID": "image-transformation-ui",
    "MULTI_PLATFORM_SELECT_LIMIT": 2
  }
  if (data) {
    return data.value;
  } else {
    return defaults[key];
  }
};
export const customOrgCodes=["CUSTOM-1","CUSTOM-2","CUSTOM-3","CUSTOM-4","CUSTOM-5","CUSTOM-6","CUSTOM-7","CUSTOM-8","CUSTOM-9","CUSTOM-10"]

export function generateRandomString(length) {
  let result=uuidv4().slice(0, length);
  return result
}
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  FormHelperText,
} from '@mui/material';
import './imageTransformation.css';
import ImageUpload from '../../Assets/ImageUpload.png'
import Select from 'react-select';
import { platformOptions } from '../../Shared/PlatformOptions';
import Header from '../Header/Header';
import axios from 'axios';
import Loader from '../../Shared/Loader'
import { getData, customOrgCodes, generateRandomString } from '../../config';
import UserService from '../../UserService/UserService';
import { uploadFile } from '../../Shared/StorageServices';
import Toast from '../../Shared/ToastMesssage';
import ConvertIcon from '@mui/icons-material/Sync';
import { ConvertedImages } from './ConveretedImages';
import Footer from '../Footer/Footer';
import { downloadImageAsFile } from '../../Shared/downloadImageAsFile';
import { validateFile } from '../../Shared/fileValidations';
export const ImageTransformation = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState("")
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedPlatformError, setSelectedPlatformError] = useState("");
  const [orgCode, setOrgCode] = useState("")
  const [orgCodeError, setOrgCodeError] = useState("")
  const [productID, setProductID] = useState("")
  const [productIDError, setProductIDError] = useState("")
  const [showLoader, setShowLoader] = useState(false)
  const [toast, setToast] = useState({ open: false, severity: '', message: '' });
  const [imageUrls, setImageUrls] = useState([]);
  const [platformErrors, setPlatformErrors] = useState([])

  const handleImageDropDown = (acceptedFiles) => {
    setImageUrls([])
    setPlatformErrors([])
    setSelectedFileError("")
    const imageValidation = validateFile(acceptedFiles[0])
    if (imageValidation === "") {
      setSelectedFile(acceptedFiles[0]);
      setSelectedFileError("")
    }
    else {
      setSelectedFileError(imageValidation)
    }
  };
  const handlePlatformChange = (selected) => {
    const limit = getData("MULTI_PLATFORM_SELECT_LIMIT");
    if (selected?.length <= limit) {
      setSelectedPlatforms(selected || []);
      setSelectedPlatformError("")
    } else {
      setToast({
        open: true,
        severity: 'error',
        message: `Max Platform Transform limit is ${limit}`,
      });
    }
  };
  const handleCloseToast = () => {
    setToast({ ...toast, open: false });
  };
  function handleError(error) {
    console.log(error)
    setToast({
      open: true,
      severity: 'error',
      message: error?.response?.data?.msg ?? error.message,
    });
    setShowLoader(false)
  }

  const selectOptions = platformOptions.map(option => ({
    value: option.code,
    label: option.name
  }));
  const convertImageValidation = () => {
    let errorFound = false
    if (selectedFile === null) {
      setSelectedFileError("Image is required")
      errorFound = true
    }
    if (selectedPlatforms.length === 0) {
      setSelectedPlatformError("Platform is required")
      errorFound = true
    }
    // if (orgCode === "") {
    //   setOrgCodeError("Org Code is required")
    //   errorFound = true
    // }
    // if (productID === "") {
    //   setProductIDError("Product ID is required")
    //   errorFound = true
    // }
    return errorFound
  }
  const processImage = async (imageUploaded, orgCode) => {
    const productId = generateRandomString(8)
    let payload = {
      reference_id: imageUploaded?.id,
      platform_names: selectedPlatforms.map(platform => platform.value),
      org_code: orgCode,
      product_id: productId
    };
    let url = getData("REACT_APP_BACKEND_BASE_URL") + '/process_images';
    await axios.post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          const fileData = response?.data?.file_data
          const imageUrls = Object.values(fileData)
            .filter(item => item.signedUrl)
            .map(item => {
              item.platformName = platformOptions.find(platform => platform.code === item.platform).name
              return item
            });
          setImageUrls(imageUrls)
          const platformErrors = Object.values(fileData)
            .filter(item => item.success !== true)
            .map(item => item.status);
          setPlatformErrors(platformErrors)
          setSelectedFile(null)
          setSelectedPlatforms([])
          setOrgCode("")
          setProductID("")
        }
        setToast({
          open: true,
          severity: 'success',
          message: "Images Converted Successfully",
        });
      });
  }
  const convertImage = async () => {
    if (convertImageValidation()) {
      return
    } else {
      try {
        const randomIndex = Math.floor(Math.random() * customOrgCodes.length);
        const orgCode = customOrgCodes[randomIndex]
        setShowLoader(true)
        const formData = new FormData();
        formData.append('fileContent', selectedFile);
        formData.append('useCase', "product_images");
        formData.append('contextId', orgCode);
        formData.append('fileType', selectedFile.type);
        formData.append('fileName', selectedFile.name);
        let imageUploaded = await uploadFile(formData, UserService.getToken(), handleError)
        if (imageUploaded) {
          await processImage(imageUploaded, orgCode)
        }
        setShowLoader(false)
      }
      catch (err) {
        handleError(err)
      }
    }
  }
  const handleDownload = async (url) => {
    setShowLoader(true)
    await axios
      .get(url, { responseType: "arraybuffer" })
      .then((response) => {
        const urlParts = url.split('/');
        let filename = urlParts[urlParts.length - 1].split('?')[0];
        filename = filename.replace(/[^a-zA-Z. ]/g, ' ').replace(/\s+/g, ' ').trim();
        downloadImageAsFile(filename, response.data, "image/jpeg");
      })
      .catch(() => {
        window.open(url, "_blank");
      });
    setShowLoader(false)
  };
  const getGridColumns = () => {
    const length = imageUrls.length;
    if (length === 1) return 12;
    if (length === 2) return 6;
    return 4;
  };
  return (
    <>
      <Header />
      {showLoader === true && (
        <div className="showloader">
          <Loader />
        </div>
      )}
      <Toast
        open={toast.open}
        handleClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
      <div style={{ marginBottom: "90px" }}>
        <Typography variant="h4" gutterBottom style={{ marginTop: "80px", color: "#003366" }}>
          Platform Image Transformer
        </Typography>
        <Card sx={{ minHeight: "71vh", maxWidth: 800, margin: '0px auto', mt: 1, mb: 4, boxShadow: 3 }}>
          <CardContent>
            <Dropzone onDrop={handleImageDropDown} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} />
                  {selectedFile ? (
                    <p>{selectedFile.name}</p>
                  ) : (
                    <>
                      <img src={ImageUpload} alt="Upload" className="upload-image" />
                      <p>Click, or drop your files here</p>
                    </>
                  )}
                </div>
              )}
            </Dropzone>
            <FormHelperText className='error-text'>{selectedFileError}</FormHelperText>
            <Box mt={2}>
              <Typography variant="h5" className="bold-text">
                Select Platforms <span className="adutStar">*</span>
              </Typography>
              <Select
                id="platforms"
                isMulti
                options={selectOptions}
                value={selectedPlatforms}
                onChange={handlePlatformChange}
                // className="multi-select-dropdown"
                classNamePrefix="select"
              />
              <FormHelperText style={{ color: "red" }}>{selectedPlatformError}</FormHelperText>
            </Box>
            {/* <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" className="bold-text">
                Org Code <span className="adutStar">*</span>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Enter Org Code"
                value={orgCode}
                onChange={(e) => { setOrgCode(e.target.value); setOrgCodeError("") }}
              />
              <FormHelperText style={{ color: "red" }}>{orgCodeError}</FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" className="bold-text">
                Product ID <span className="adutStar">*</span>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Enter Product ID"
                value={productID}
                onChange={(e) => {
                  setProductID(e.target.value);
                  setProductIDError("");
                }}
              />
              <FormHelperText style={{ color: "red" }}>{productIDError}</FormHelperText>
            </Grid>
          </Grid> */}
            <Button
              variant="contained"
              color='primary'
              sx={{ my: 2 }}
              onClick={convertImage}
              startIcon={<ConvertIcon />}
            >
              Convert
            </Button>
            <ConvertedImages
              imageUrls={imageUrls}
              handleDownload={handleDownload}
              platformErrors={platformErrors}
              getGridColumns={getGridColumns}
            />
          </CardContent>
        </Card>
      </div>
      <Footer />
    </>
  );
};


